import { merge as objectMerge } from '@tcc/shared/src/helpers/object';
import SchemaHandler  from '@tcc/shared/src/schema/schemaHandler';

import { sendPageEvent } from '../../../integrations/google/ga';
import { sendGenericConversion } from '../../../integrations/tealium/tealium';
import { createEventProperties, createTData } from '../eventProperties';

class AddGenericConversion extends SchemaHandler {
  process() {
    super.process({
      TEALIUM: (input) => { this._handleTealium(input); },
      GA: (input) => { this._handleGA(input); },
      EVENT_SVC: (input) => { this._handleEventSvc(input); }
    });
  }

  _handleGA(input) {
    sendPageEvent(
      input.eid,
      input,
      objectMerge(
        createTData(this.data).getProperties(),
        createEventProperties('genConv').getProperties(),
        this.extras),
      true);
  }

  _handleTealium(input) {
    sendGenericConversion(input);
  }

  _handleEventSvc(input) {
    const properties = objectMerge(input.properties, {
      // event service expects these to come through custom properties
      // and since we're reusing the addEvent interfacehere , there are
      // no first class fields for the below
      area: input.area,
      revenue: input.revenue,
      product: input.product,
      action: input.action
    });

    this.schemaHelper.handleSchema(
      'add_event',
      undefined,
      'v1',
      objectMerge(input, { type: 'genConv', properties }),
      this.extras,
      // This call should only go to the event service
      // The current handler will handle calls to GA/Tealium
      ['EVENT_SVC']);
  }
}

export default AddGenericConversion;
