import _config from '@tcc/shared/src/helpers/config';

_config.merge({
  'tcc.listenerDisabled': false,
  'tcc.manualPagePerf': false,
  'tcc.loadTTI': true,
  'tcc.eventDelayMs': 75,
  'tcc.cookies.sameSite': 'none',
  'tcc.consentDelayMs': 500,
  'tcc.gaAccountDev': 'UA-52262008-15',
  'tcc.gaAccountTest': 'UA-37178807-21',
  'tcc.gaAccountProd': 'UA-115508484-1',
  'tcc.ga4AccountDev': 'G-QR3LRHZZ2J',
  'tcc.ga4AccountTest': 'G-XVYQ3S2DCT',
  'tcc.ga4AccountProd': 'G-R9B47BVCG7',
  'tcc.sGtmAccountDev': 'TPY4ZF3V04',
  'tcc.sGtmAccountTest': '1LZCFHGZM9',
  'tcc.sGtmAccountProd': 'QJXPZE4TX8',
  'tcc.webGtmAccountDev': 'GTM-TFQDS47',
  'tcc.webGtmAccountTest': 'GTM-T889HFJ',
  'tcc.webGtmAccountProd': 'GTM-5WB8X7D',
  'tcc.webGtmEnabled': true,
  'tcc.eventBusEnabled': true,
  'tcc.buildEnv': 'prod',
  'tcc.buildName': '@tcc/tcc',
  'tcc.buildVersion': '3.45.4',
  'tcc.buildDate': '2023-08-10T21:37:39.985Z',
  'tcc.eventBusApiKey': 'b18ef4f046435b64a469b32c3c1c20a3',
  'tcc.eventBusRigorApiKey': '8da2217409854bee82e12dc4ca0b39fb',
  'tcc.ttiUrl': 'https://img6.wsimg.com/wrhs/ce554d2333f3801abafb32da18213ff7/tti.min.js',
  'tcc.sGtmHost': '',
  'tcc.privateLabelId': ''
});

export default _config;
