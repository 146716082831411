import AbstractEventHandler from '../../csp/handlers/abstractEventHandler';
import { merge } from '@tcc/shared/src/helpers/object';

import trackingValues from '@tcc/shared/src/helpers/trackingValues';

import experimentTracker from '../experimentTracking';
import { EXPERIMENT_ASSIGNMENT_SCHEMA_ID } from '../../../utils/schemaConstants';
import { buildTrafficData } from '../../../utils/eventBusUtils';

const _excludedVariants = ['ineligible', 'inelligible'];

class AddExperimentAssignmentHandler extends AbstractEventHandler { // eslint-disable-line id-length

  process() {
    for (const variant of _excludedVariants) {
      if (this.data.ALL.variant_id.toLowerCase().includes(variant))
        return;
    }
    super.process();
  }

  _getEvents(input) {
    return [{
      schemaId: EXPERIMENT_ASSIGNMENT_SCHEMA_ID,
      data: {
        experimentId: input.experiment_id,
        experimentSource: input.experiment_source,
        variantId: input.variant_id,
        traffic: buildTrafficData({ customProperties: input.custom_properties })
      }
    }];
  }

  _handleLegacy(input) {
    // call the 'add_event' schema
    this.schemaHelper.handleSchema('add_event', undefined, 'v1', {
      type: 'experiment',
      eid: 'traffic.tcc.instrumentation.experiment.add_experiment',
      custom_properties: merge({
        experiment_type: this.schemaType || input.experiment_type,
        experiment_id: input.experiment_id,
        experiment_source: input.experiment_source,
        variant_id: input.variant_id,
        content_id: input.content_id
      }, input.custom_properties)
    },
    this.extras,
    // this schema's sinks will override add_event's sinks
    this.sinks);

    experimentTracker.set(input.experiment_id, input.variant_id);
    trackingValues.set('experiments', experimentTracker.getProperties());
  }
}

export default AddExperimentAssignmentHandler;
