import { CLICK_SCHEMA_ID } from '../../../utils/schemaConstants';
import AddElementAction from './addElementAction';

class AddClick extends AddElementAction {
  _getSchemaId() {
    return CLICK_SCHEMA_ID;
  }

  _getActionType() {
    return 'click';
  }

  /**
   * @param { object } input { element, traffic, promotion }
   * @description Identifies whether or not the incoming click event
   * would have been a promotion or generic "add event" type in legacy traffi
   * and calls the respective handler.
   */
  _handleLegacy(input) {
    const type = this._getActionType();
    const legacyPayload = this._transformLegacyPromo(type, input.element, input.traffic, input.promotion);
    if (this._isPromo(input)) {
      // Send to the legacy add_promotion handler with corresponding type
      this.schemaHelper.handleSchema(
        'add_promotion',
        type,
        'v2',
        legacyPayload,
        this.extras);
    } else {
      // Call the legacy generic add event handler
      this.schemaHelper.handleSchema(
        'add_event',
        undefined,
        'v2',
        legacyPayload,
        this.extras);
    }
  }
}

export default AddClick;
