import { merge } from '@tcc/shared/src/helpers/object';

import AbstractEventHandler from '../../csp/handlers/abstractEventHandler';
import { sendEvent } from '@tcc/shared/src/integrations/eventService/eventService';

import { sendEcommerceEvent } from '../../../integrations/google/ga';
import { sendEcommerceEvent as sendTealiumEcommerceEvent } from '../../../integrations/tealium/tealium';
import { createEventProperties, addEventProperties, createTData, getEventSvcProperties } from '../../../interfaces/traffic/eventProperties';
import { ADD_TO_CART_SCHEMA_ID, CHECKOUT_PROGRESS_SCHEMA_ID,
  REMOVE_FROM_CART_SCHEMA_ID, TRANSACTION_SCHEMA_ID } from '../../../utils/schemaConstants';
import { buildTrafficData } from '../../../utils/eventBusUtils';
import { parseBoolean } from '@tcc/shared/src/utils/stringUtils';

let _isDuplicatePurchase = false;

const _reservedCustomPropertyKeys = [
  'cartEditProduct',
  'cartEditProductIdBefore',
  'cartEditProductIdAfter',
  'cartEditQtyBefore',
  'cartEditQtyAfter',
  'cart_payment_reason_response_code',
  'cart_payment_billing_country',
  'cart_payment_error_code'
];

const _buildCartChange = (customProperties) => {
  return {
    editFlag: customProperties.cartEditProduct && parseBoolean(customProperties.cartEditProduct),
    beforePfid: customProperties.cartEditProductIdBefore,
    afterPfid: customProperties.cartEditProductIdAfter,
    beforeQuantity: customProperties.cartEditQtyBefore && parseInt(customProperties.cartEditQtyBefore, 10),
    afterQuantity: customProperties.cartEditQtyAfter && parseInt(customProperties.cartEditQtyAfter, 10)
  };
};

class AddEcommEventHandler extends AbstractEventHandler {
  preProcess() {
    if (this.schemaType === 'purchase') {
      if (_isDuplicatePurchase) {
        throw 'cmd: LogEcommEvent duplicate purchase event detected, ignoring duplicate';
      }
      _isDuplicatePurchase = true;
    }

    this._buildEvent();
  }

  process() {
    super.process({
      TEALIUM: (input) => { this._handleTealium(input); },
      GA: (input) => { this._handleGA(input); },
      EVENT_SVC: (input) => { this._handleEventSvc(input); }
    });
  }

  _handleTealium(input) {
    sendTealiumEcommerceEvent(this.schemaType, input);
  }

  _handleGA(input) {
    sendEcommerceEvent(this.schemaType, input, merge(this.tData.getProperties(), this.pageEvent.getProperties()));
  }

  _handleEventSvc(input) {
    sendEvent(
      merge(this.pageEvent.getProperties(), getEventSvcProperties(), input),
      '/pageEvents.aspx');
  }

  _hasConversion() {
    return this.schemaType === 'purchase';
  }

  _buildEvent() {
    this.tData = createTData(this.data);

    // In order to maintain purchase events going to legacy, we need to use 'page.event' when sending purchase events
    // However, lets use the current schema type when this is not an event which needs to go to legacy.
    this.pageEvent = createEventProperties(this.schemaType === 'purchase' ?
      'page.event' : this.schemaType, this.tData);

    addEventProperties(this.pageEvent, 'ecommerce');

    // Set the hit_id from the page handler extras. This hit_id is persisted
    // from an upstream handler which may have called this handler.
    this.pageEvent.set('hit_id', this.extras.hit_id);
  }

  _getWebContext(input) {
    const products = [];
    if (input.items) {
      input.items.forEach(product => {
        products.push({
          fullProductName: product.full_product_name,
          productCategoryId: product.product_category_id,
          cjProductId: product.cj_product_id,
          cjProductPriceUsd: parseFloat(product.cj_product_price_usd),
          cjProductQuantity: parseInt(product.cj_product_quantity, 10)
        });
      });
    }
    return {
      orderRegion: input.order_region,
      pageType: input.page_type,
      products,
      hashedEmail: input.hashed_email
    };
  }

  _getEvents(input) {
    const products = [];
    if (input.items) {
      input.items.forEach((payload) => {
        products.push({
          productId: payload.id,
          // TODO: Update all parseFloat/parseInt to check whether
          // there is a value that we can parse first
          priceUsd: parseFloat(payload.price),
          productName: payload.name,
          quantity: parseInt(payload.quantity, 10),
          couponCode: payload.coupon,
          itemTrackingCode: payload.item_tracking_code });
      });
    }

    const packages = [];
    if (input.pkgs) {
      input.pkgs.forEach((payload) => {
        packages.push({
          id: payload.package_id,
          priceUsd: parseFloat(payload.pkgpr),
          quantity: parseInt(payload.pkgqt, 10),
          category: payload.pkgca });
      });
    }

    const basket = {
      currencyCode: input.currency
    };

    const cart = {
      type: input.cart_type
    };

    // To be used for building cart object, which maps custom properties
    const originalProperties = this.tData.getProperties();

    // Copy custom properties / filter out for the event bus payload so that
    // we don't have duplicate first class fields w/in a property bag
    const customProperties = merge(originalProperties);
    _reservedCustomPropertyKeys.forEach(key => {
      delete customProperties[key];
    });

    const traffic = buildTrafficData({ customProperties });

    switch (this.schemaType) {
      case 'add_to_cart':
        return [{
          schemaId: ADD_TO_CART_SCHEMA_ID,
          data: {
            cart: merge(cart, _buildCartChange(originalProperties)),
            basket,
            packages,
            products,
            traffic
          }
        }];
      case 'begin_checkout':
      case 'checkout_progress':
        return [{
          schemaId: CHECKOUT_PROGRESS_SCHEMA_ID,
          data: {
            cart,
            basket: merge(basket, { couponCode: input.coupon }),
            checkout: {
              option: input.checkout_option,
              step: parseInt(input.checkout_step, 10)
            },
            payment: {
              processor: input.payment_processor,
              reasonResponseCode: originalProperties.cart_payment_reason_response_code,
              billingCountry: originalProperties.cart_payment_billing_country,
              errorCode: originalProperties.cart_payment_error_code
            },
            packages,
            products,
            traffic
          }
        }];
      case 'remove_from_cart':
        return [{
          schemaId: REMOVE_FROM_CART_SCHEMA_ID,
          data: {
            cart: merge(cart, _buildCartChange(originalProperties)),
            basket,
            packages,
            products,
            traffic
          }
        }];
      case 'purchase':
        return [{
          schemaId: TRANSACTION_SCHEMA_ID,
          data: {
            cart: merge(cart, { activationRedirectFlag: parseBoolean(input.activation_redirect) }),
            basket: merge(basket, { couponCode: input.coupon }),
            order: {
              discountUsd: parseFloat(input.order_discount_usd),
              fromWebsiteFlag: parseBoolean(input.order_from_website),
              totalNewUsd: parseFloat(input.order_total_new_usd),
              totalRenewalUsd: parseFloat(input.order_total_renewal_usd),
              valueUsd: parseFloat(input.value),
              firstOrderFlag: parseBoolean(input.first_order),
              newCustomerFlag: parseBoolean(input.new_customer),
              newVsRenewal: input.new_vs_renewal,
              transactionId: input.transaction_id
            },
            payment: {
              processor: input.payment_processor,
              pendingFlag: parseBoolean(input.payment_pending)
            },
            products,
            traffic
          }
        }];
    }
  }
}

const _setDuplicatePurchaseFlag = (value) => {
  _isDuplicatePurchase = value;
};

export default AddEcommEventHandler;

// Private exports for testing
export { _setDuplicatePurchaseFlag };
