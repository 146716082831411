import { merge as objectMerge } from '@tcc/shared/src/helpers/object';
import { getWindow as getBrowserWindow } from '@tcc/shared/src/helpers/browser';

import SchemaHandler from '@tcc/shared/src/schema/schemaHandler';

class AddVirtualPagePerfHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: (input) => { this._handle(input); }
    });
  }

  _handle(input) {
    let timingProperties = input.timing_metrics;
    const virtualPath = input.virtual_path;
    if (this.schemaVersion === 'v2') {
      timingProperties = input.timing.navigation;
    }
    // call the 'add_perf' schema
    this.schemaHelper.handleSchema('add_perf', undefined, 'v1', {
      type: 'pageperf',
      properties: objectMerge(timingProperties, {
        nav_type: 'soft',
        navigationType: input.type,
        // TODO: Remove after legacy traffic feeds have been deprecated
        page: virtualPath,
        virtual_path: virtualPath }),
      custom_properties: this.data.EVENT_SVC.custom_properties
    },
    this.extras,
    // this schema's sinks will override add_perf's sinks
    this.sinks);

    // Set a performance mark if name is provided and an error did not occur while processing
    // the virtual perf event.
    const w = getBrowserWindow();
    if (input.perf_mark_name && w.performance && w.performance.mark) {
      w.performance.mark(input.perf_mark_name);
    }
  }
}

export default AddVirtualPagePerfHandler;
