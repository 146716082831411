import { isDelegated, getCustomerId, getDelegateCustomerId, getEmployeeId, getFederationPartnerId } from '@tcc/shared/src/helpers/auth';
import VisitHelperTcc from '../../helpers/visitHelperTcc';
import { getClientId } from '../../integrations/google/tracker';
import { getNavigator, getWindow, getDocument, getHost } from '@tcc/shared/src/helpers/browser';
import config from '@tcc/shared/src/helpers/config';
import { hasAnalyticsConsent, hasMarketingConsent } from '@tcc/shared/src/helpers/policy';
import { getContentGroup, getPrivateLabelId } from '@tcc/shared/src/helpers/page';
import { getCleanUrl } from '@tcc/shared/src/helpers/url';
import page from '@tcc/shared/src/interfaces/traffic/eventPageProperties';
import { findCookie } from '@tcc/shared/src/helpers/cookie';
import { merge } from '@tcc/shared/src/helpers/object';

const visitHelper = new VisitHelperTcc();

const _keyFnMap = {
  'context.visitorId': ({ visitInfo }) => visitInfo.visitorGuid,
  'context.sessionId': ({ visitInfo }) => visitInfo.visitGuid,
  'context.customerId': () => getCustomerId(),
  'context.googleClientId': () => getClientId(),
  'context.isDelegated': ({ delegated }) => delegated,
  'context.parent.customerId': ({ delegated }) => delegated ? getDelegateCustomerId() : undefined,
  'context.parent.agentId': ({ delegated }) => delegated ? getEmployeeId() : undefined,
  'client.userAgent': () => getNavigator().userAgent,
  // TODO: When we modularize our client, rename this to SCC
  'client.sdk.name': () => 'tcc',
  'client.sdk.version': () => config.get('tcc.buildVersion'),
  'client.device.viewportWidth': ({ win, doc }) => win.innerWidth ? win.innerWidth : doc.body.offsetWidth,
  'client.device.viewportHeight': ({ win, doc }) => win.innerHeight ? win.innerHeight : doc.body.offsetHeight,
  'client.device.screenResolutionWidth': ({ win }) => win.screen.width,
  'client.device.screenResolutionHeight': ({ win }) => win.screen.height,
  'consent.analyticsFlag': () => hasAnalyticsConsent(),
  'consent.marketingFlag': () => hasMarketingConsent(),
  'page.id': () => config.get('tcc.pageId'),
  'page.traceId': () => page.get('trace_id'),
  'page.contentGroup': () => getContentGroup(),
  'page.host': () => getHost(),
  'page.path': () => getWindow().location.pathname,
  'page.virtualPath': () => page.get('virtual_path'),
  'page.location': () => getCleanUrl(),
  'page.referrer': ({ doc }) => doc.referrer,
  'page.sessionPageViewCount': ({ visitInfo }) => visitInfo.pageCount,
  'site.federationPartnerId': () => getFederationPartnerId(),
  'site.market': () => findCookie('market'),
  'site.privateLabelId': () => {
    const plid = getPrivateLabelId();
    return plid && parseInt(getPrivateLabelId(), 10);
  }
};

class GlobalContext {
  constructor() {
    this.context = {};
    this.version = 0;
  }

  init({ traceId }) {
    this.context = {
      traceId,
      context: {}
    };
  }

  updateContext() {
    const visitInfo = visitHelper.getVisitInfo();
    const win = getWindow();
    const doc = getDocument();
    const delegated = isDelegated();

    let hasChanged = false;
    const newContext = {
      traceId: this.context.traceId
    };

    Object.entries(_keyFnMap).forEach(([path, fn]) => {
      const keys = path.split('.');

      const newValue = fn({ visitInfo, delegated, win, doc });
      let existingValue;
      let propKey;

      let existingParent = this.context;
      let newParent = newContext;
      keys.forEach((key, index) => {
        if (index < keys.length - 1) {
          existingParent = existingParent[key] || {};
          newParent[key] = newParent[key] || {};
          newParent = newParent[key];
        } else {
          propKey = key;
          existingValue = existingParent[key];
        }
      });

      if (existingValue !== newValue) {
        hasChanged = true;
      }

      if (typeof newValue !== 'undefined') {
        newParent[propKey] = newValue;
      }
    });

    if (hasChanged) {
      this.version++;
      this.context = newContext;
    }
  }
}

const _globalContext = new GlobalContext();

/**
 * @name updateConsent
 * @param {Object} globalContext the global context which needs to be updated
 * @description Update analytics and marketing consent within an existing
 * global context using current values from the consent cookie
 */
const updateConsent = (globalContext) => {
  const global = globalContext || {};
  global.consent = merge(global.consent, {
    analyticsFlag: hasAnalyticsConsent(),
    marketingFlag: hasMarketingConsent()
  });
};

/**
 * @name updateConsent
 * @param {Object} globalContext the global context which needs to be updated
 * @description Update analytics and marketing consent within an existing
 * global context using current values from the consent cookie
 */
const updateGoogleIds = (globalContext) => {
  const global = globalContext || {};
  global.context = merge(global.context, {
    googleClientId: getClientId()
  });
};

export default _globalContext;

export {
  updateConsent,
  updateGoogleIds
};

// Private exports for unit tests
export {
  GlobalContext
};
